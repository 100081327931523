import { useTranslation } from "react-i18next";
import { Roots } from "../../config/root";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

function Accreditation() {
    const { t, i18n } = useTranslation();



    const [gender, setgender] = useState(0)
    const [name, setname] = useState("")
    const [jobs, setjobs] = useState("")
    const [company, setcompany] = useState("")
    const [email, setemail] = useState("")
    const [phone, setphone] = useState("")

    const [presscard, setpresscard] = useState("")
    const [pressorgan, setpressorgan] = useState("")
    const [presstype, setpresstype] = useState("")

    const [activityarea, setactivityarea] = useState("")
    const [activitylink, setactivitylink] = useState("")

    const [files, setfiles] = useState(null)
    const [notice, setnotice] = useState("")

    const [errormessage, seterrormessage] = useState(null)


    const control = () => {
        
    }
    const send = () => {

    }

    /*
    const select = async (e) => {
        const [file] = e.target.files;
        setform4input1(file)
    }
    const control = () => {
        if (form1input1 === 0) {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form1input2 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form1input3 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form1input4 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form1input5 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form1input6 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }

        if (form2input1 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form2input2 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form2input3 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }

        if (form3input1 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }
        if (form3input2 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }

        if (form4input1 === null) {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }

        if (form5input1 === "") {
            const e = document.getElementById("error-container");
            if (e) {
                seterrormessage(t('formmessage.error'))
                e.scrollIntoView({ behavior: "smooth" });
            }
            return false;
        }

        return true
    }
    const send = async () => {

        if (control()) {
            const formData = new FormData();
            formData.append('path', "document");
            formData.append('file', form4input1);
            const response = await axios.post(`${Roots({ is: "upload" })}`, formData, { headers: { 'Content-Type': 'multipart/form-data', }, });
            let result = `${Roots({ is: "file" })}/document/${response.data.file}`
            var respec = ""
            if (form1input1 === 1) {
                respec = "Sehr geehrte Frau " + form1input2;
            }
            else {
                respec = "Sehr geehrter Herr " + form1input2
            }
            const content1 = "" +
                "" + respec + "<br/>" +
                "<br/>" +
                "Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br/>" +
                "<br/>" +
                "Freundliche Grüsse" +
                "<br/><br/>" +
                "Kompass Events<br/>" +
                "Schaufelbergerstrasse 57 <br/>" +
                "CH-8055 Zürich<br/><br/>" +
                "info@kompass.events<br/>" +
                "+41 (0) 44 576 60 80<br/>";
            axios.post(Roots({ is: "api" }), {
                params: "send-contact",
                email: form1input5,
                name: "Kompass Events",
                title: "Ihre Nachricht ist bei uns angekommen",
                content: content1
            })

            const content2 = "" +
                "Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf." +
                "<br/><br/>" +
                "Anrede: " + form1input1 === 1 ? "Frau" : "Herr" + "<br/>" +
                "Name und Vorname: " + form1input2 + "<br/>" +
                "Berufsbezeichnung: " + form1input3 + "<br/>" +
                "Institution/Organisation: " + form1input4 + "<br/>" +
                "E-Mail: " + form1input5 + "<br/>" +
                "Telefon: " + form1input6 + "<br/>" +
                "Presseausweisnummer: " + form2input1 + "<br/>" +
                "Name des Publikationsorgans: " + form2input2 + "<br/>" +
                "Art des Publikationsorgans : " + form2input3 + "<br/>" +
                "Spezifisches Interesse an der Veranstaltung  : " + form3input1 + "<br/>" +
                "Frühere Teilnahmen/Veröffentlichungen  : " + form3input2 + "<br/>" +
                "Zusätzliche Notizen  : " + form4input1 + "<br/>" +
                "Dokument:  <a href=" + result + ">Dokument</a><br/>" +
            "<br/>";
            axios.post(Roots({ is: "api" }), {
                params: "send-contact",
                email: "info@kompass.events",
                name: form1input5,
                title: "Neue Nachricht",
                content: content2
            })


        }
    }
    */



    return (
        <>
            <div id="error-container" className="h-300px bg-gray-900 d-flex align-items-center" style={{ backgroundImage: `url(${Roots({ is: "file" })}/banner/banner.jpg)` }}>
                <div className="container">
                    <div className="text-white fw-bolder fs-1 text-uppercase">{t("banner.accreditation")}</div>
                </div>
            </div>

            <div className="container">
                <div className="row pt-10 pb-10">
                    <div className="col-lg-12 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="fs-3 fw-bolder">{t("akkreditierung.global.title")}</div>
                                <div className="fs-4 fw-normal">{t("akkreditierung.global.text")}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 mb-2" hidden={errormessage ? false : true}>
                        <div className="alert alert-danger">
                            <div>
                                {errormessage}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 mb-2">
                        <div className="card">
                            <div className="card-header pt-2 pb-2 min-h-50px d-flex align-items-center">
                                <div className="fs-3 fw-bolder">{t("akkreditierung.form1.title")}</div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form1.data.input1")}</div>
                                        <select className="form-select">
                                            <option value={0}>{t("akkreditierung.form1.data.input1")}</option>
                                            <option value={1}>{t("akkreditierung.form1.select.flame")}</option>
                                            <option value={2}>{t("akkreditierung.form1.select.male")}</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form1.data.input2")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input2")} />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form1.data.input3")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input3")} />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form1.data.input4")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input4")} />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="fs-12 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form1.data.input5")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input5")} />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form1.data.input6")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input6")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                        <div className="card mb-2">
                            <div className="card-header pt-2 pb-2 min-h-50px d-flex align-items-center">
                                <div className="fs-3 fw-bolder">{t("akkreditierung.form2.title")}</div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form2.data.input1")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form2.data.input1")} />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form2.data.input2")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input2")} />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="fs-12 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form2.data.input3")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form1.data.input3")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                        <div className="card">
                            <div className="card-header pt-2 pb-2 min-h-50px d-flex align-items-center">
                                <div className="fs-3 fw-bolder">{t("akkreditierung.form3.title")}</div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form3.data.input1")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form3.data.input1")} />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form3.data.input2")}</div>
                                        <input className="form-control" placeholder={t("akkreditierung.form3.data.input2")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-12 mb-2">
                        <div className="card">
                            <div className="card-header pt-2 pb-2 min-h-50px d-flex align-items-center">
                                <div className="fs-3 fw-bolder">{t("akkreditierung.form4.title")}</div>
                            </div>
                            <div className="card-body">
                                <div className="h-100px border d-flex align-items-center justify-content-center rounded-3 cursor-pointer" onClick={() => { document.getElementById("select-file").click() }}>
                                    <div>
                                        <div className="fs-5 fw-bold text-gray-600" hidden={files === null ? true : false}>
                                            {files === null ? "" : files.name}
                                        </div>
                                        <i className="fa-solid fa-cloud-arrow-up me-2 fs-3" hidden={files === null ? false : true}></i>
                                        <span className="fs-5 fw-bold text-gray-600" hidden={files === null ? false : true}>{t("akkreditierung.form4.data.input1")}</span>
                                        <input id="select-file" type="file" onChange={(e) => { }} hidden />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 mb-2">
                        <div className="card">
                            <div className="card-header pt-2 pb-2 min-h-50px d-flex align-items-center">
                                <div className="fs-3 fw-bolder">{t("akkreditierung.form5.title")}</div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="fs-6 text-gray-500 fw-semibold mb-2">{t("akkreditierung.form5.data.input1")}</div>
                                        <textarea className="form-control" placeholder={t("akkreditierung.form5.data.input1")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-12 mb-2">
                        <button className="btn btn-kompass text-white w-100 rounded-2" onClick={() => { send() }}>
                            {t("button.send")}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
export default Accreditation;